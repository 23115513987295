<template>
  <b-overlay
    :show="!users"
    rounded="sm"
  >
    <div
      v-if="!done"
      class="my-1 d-flex spinner-border text-primary justify-content-center"
      style="width: 2.5rem; height: 2.5rem; margin: auto;"
      role="status"
    />
    <div
      v-else
      class="users-table"
    >
      <b-form-select
        v-model="statuses"
        :options="userStatusOptions"
        style="width: 16rem"
        class="c-select"
        @change="onStatusFilter"
      />
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
              debounce="800"
            />
          </div>
        </b-form-group>
      </div>
      <!-- table -->
      <vue-good-table
        v-if="users"
        mode="remote"
        :total-rows="totalRecords"
        :is-loading.sync="isLoading"
        :pagination-options="{
          enabled: true,
          perPage:serverParams.perPage
        }"
        :rows="users"
        :columns="columns"
        :rtl="direction"
        :sort-options="{
          enabled: true,
          multipleColumns: true,
        }"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch"
      >
        <template slot="loadingContent">
          <div
            class="my-1 d-flex spinner-border text-primary justify-content-center"
            style="width: 2.5rem; height: 2.5rem; margin: auto;"
            role="status"
          />
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: User -->
          <span v-if="props.column.field === 'full_name'">
            <div
              style="text-align:left; width:170px;display: flex; align-items: center;"
            >
              <feather-icon
                v-if="props.row.user_status !== 9 && props.row.administrator !== 1"
                icon="UsersIcon"
                size="16"
                class="impersonateIcon"
                @click="impersonate(props.row.id)"
              />
              <feather-icon
                v-else-if="props.row.user_status === 9 || props.row.administrator === 1"
                icon="ShieldIcon"
                filled
                size="16"
                class="adminIcon"
              />
              &nbsp;<router-link
                v-if="props.row.user_status !== 9 && props.row.administrator !== 1"
                :to="`/user/details/${props.row.uid}`"
              >{{ props.row.full_name }}
                <div v-if="props.row.user_status == 4 && (props.row.tag == null || props.row.tag == '')">
                  (<span class="text-danger font-weight-bold">
                    No tag set
                  </span>)
                </div>
              </router-link>
              <router-link
                v-else
                to="#"
                class="adminClass"
              >{{ props.row.full_name }}</router-link>
            </div>
            <!-- <span
                v-if="props.row.user_status === 9"
                class="adminClass"
              >
                {{ props.row.full_name }}
              </span> -->
          </span>

          <!-- Column: Company Name -->
          <span v-else-if="props.column.field === 'company'">
            <span v-if="props.row.user_status === 9">TrafficManager</span>
            <span v-else-if="props.row.administrator === 1">TrafficManager</span>
            <span v-else-if="props.row.company">{{ props.row.company }}</span>
            <span v-else>N/A</span>
          </span>

          <!-- Column: Domain -->
          <span v-else-if="props.column.field === 'concat_domain'">
            <a
              v-if="props.row.concat_domain"
              :href="`//${props.row.concat_domain}`"
              target="_blank"
            >{{ props.row.concat_domain }}</a>
            <span v-else>N/A</span>
          </span>

          <!-- Column: clicks -->
          <span v-else-if="props.column.field === 'user_stats_sum_clicks'">
            <span>{{ props.row.user_stats_sum_clicks != null ? props.row.user_stats_sum_clicks : 0 }}</span>
          </span>

          <!-- Column: Date -->
          <!-- eslint-disable vue/no-v-html -->
          <span
            v-else-if="props.column.field === 'date'"
            v-html="getStartedDate( props.row)"
          />
          <!--eslint-enable-->

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'user_status'">
            <b-badge
              :style="props.row.user_status === 8 ? 'color:white;background:fuchsia;' : props.row.user_status === 10 ? 'color:white;background:darkorange;' : 'color:white;'"
              :variant="statusVariant(userStatus(props.row))"
            >
              {{ userStatus(props.row) }}
            </b-badge>
          </span>

          <!-- Column: Email -->
          <span v-else-if="props.column.field === 'email'">
            <p style="color: #b0387f !important">{{ props.row.email }}</p>
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="props.column.field === 'action' && props.row.user_status !== 9 && !props.row.administrator"
            class="d-flex align-items-center flex-column"
          >
            <a
              v-if="props.row.user_status === 6"
              href="javascript:void(0)"
              class="user-action-cancel"
            >
              <feather-icon
                icon="XSquareIcon"
                size="22"
                class="align-middle"
              />
            </a>
            <a
              v-else
              href="javascript:void(0)"
              class="text-danger"
              @click.prevent="cancelUser(props.row.uid)"
            >
              <feather-icon
                icon="XSquareIcon"
                size="22"
                class="align-middle"
              />
            </a>
            <a
              href="javascript:void(0)"
              class="text-info"
              @click.prevent="viewClicksAndConversions(props.row.full_name)"
            >
              <feather-icon
                icon="ArrowRightIcon"
                size="22"
                class="align-middle"
              />
            </a>
            <a
              :id="`popover-button-sync`+props.row.id"
              href="javascript:void(0)"
              class="text-success"
            >
              <feather-icon
                icon="TagIcon"
                size="22"
                class="align-middle"
              />
              <b-popover
                :target="`popover-button-sync`+props.row.id"
                triggers="hover"
                placement="left"
              >
                <div style="margin:20px;">
                  <span v-show="askAction">
                    <strong>Please select a tag</strong>
                    <v-select
                      id="tag"
                      v-model="selectedTag"
                      :options="tags"
                      label="name"
                      @input="setSelectedTag"
                    />
                    <b-button
                      v-b-modal.users-tag-modal
                      type="button"
                      variant="outline-primary"
                      size="sm"
                      style="margin-top:5px; width: 100%;"
                    >Add/Edit Tag</b-button>
                  </span>
                  <span v-show="askConfirm">
                    <strong>Are you sure to confirm?</strong>
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-sm"
                      style="margin:3px; width: 100%;"
                      :disabled="sendingConfirmYes"
                      @click="confirmYes(props.row.uid)"
                    >
                      <span v-if="sendingConfirmYes">
                        <b-spinner
                          small
                          type="grow"
                        />
                        Confirming...
                      </span>
                      <span v-else>Yes</span>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-danger btn-sm"
                      style="margin:3px; width: 100%;"
                      @click="answerNo"
                    >No</button>
                  </span>
                </div>
              </b-popover>
            </a>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap mt-1">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="serverParams.perPage"
                :options="['10','25','50','100','150', '200']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ totalRecords }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="totalRecords"
                :per-page="serverParams.perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
    <!-- Change status modal -->
    <b-modal
      id="users-tag-modal"
      ref="modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Add"
      centered
      title="Add/Edit tag"
      no-close-on-backdrop
      @ok="handleOkTag"
    >
      <b-form ref="form">
        <b-row>
          <b-col cols="12">
            <validation-observer ref="rules">
              <b-form-group
                label="Add New Tag"
              >
                <validation-provider
                  #default="{ errors }"
                  name="tag"
                  rules="required|min:2"
                >
                  <b-form-input
                    id="tag"
                    v-model="newTag"
                    required
                    placeholder="Enter tag name"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small><hr>
                </validation-provider>
                <b-form-group
                  label="Tag to Edit/Delete"
                >
                  <b-form-select
                    id="template"
                    v-model="selectedTag"
                    placeholder="Enter template"
                  >
                    <option
                      v-for="tag in tags"
                      :key="tag.id"
                    >
                      {{ tag.name }}
                    </option>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  v-if="selectedTag"
                  label="Edit Tag Name"
                  class="mt-1"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="tag"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="tag"
                      v-model="selectedTagEdit"
                      required
                      placeholder="New tag name"
                      autocomplete="off"
                    />
                    <small class="text-warning">The name of the tag will be changed for all users !</small><br>
                    <small class="text-danger">{{ errors[0] }}</small><br>
                    <div class="d-flex">
                      <button
                        class="btn btn-sm btn-warning mr-1"
                        type="button"
                        @click="editTagName"
                      >
                        Edit
                      </button>
                      <button
                        class="btn btn-sm btn-danger"
                        type="button"
                        @click="deleteTagName"
                      >
                        Delete
                      </button>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-form-group>
            </validation-observer>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BBadge, BOverlay, BPopover,
  BSpinner, BModal, BButton, BRow, BCol, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'
import store from '@/store/index'
import moment from 'moment'
import axios from '@/libs/axios'

import 'vue-select/dist/vue-select.css'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BButton,
    BModal,
    BFormInput,
    BFormSelect,
    BOverlay,
    BBadge,
    BPopover,
    vSelect,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['changeTab'],
  data() {
    return {
      pageLength: 50,
      dir: false,
      columns: [
        {
          label: 'User',
          field: 'full_name',
        },
        {
          label: 'Company Name',
          field: 'company',
        },
        {
          label: 'Domain',
          field: 'concat_domain',
        },
        {
          label: 'Clicks',
          type: 'number',
          field: 'user_stats_sum_clicks',
        },
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Status',
          field: 'user_status',
          sortable: false,
          searchable: true,
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Tag',
          field: 'tag',
          sortable: false,
          formatFn: value => {
            if (!value) {
              return 'N/A'
            }
            return value
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      users: [],
      searchTerm: '',
      done: false,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        search: '',
        columnFilters: {},
        sort: [
          {
            field: 'id',
            type: 'desc',
          },
        ],
        page: 1,
        perPage: 50,
      },
      askAction: true,
      askConfirm: false,
      askReject: false,
      sendingConfirmYes: false,
      selectedTag: '',
      tags: [],
      newTag: '',
      selectedTagEdit: '',
      statuses: 'Filter By Status',
      userStatusOptions: [
        'Filter By Status',
        'Registered',
        'Pending',
        'Trial',
        'Expired',
        'Suspended',
        'Backup',
      ],
    }
  },
  computed: {
    statusVariant() {
      const presentationColor = {
        /* eslint-disable key-spacing */
        Lead: 'light-info',
        Registered: 'light-primary',
        Pending: 'light-warning',
        Trial: 'light-warning',
        Subscribed: 'light-success',
        Canceled: 'light-secondary',
        Affiliate: 'secondary text-white',
        Expired: 'light-danger',
        // make backup different from all others to make it stand out
        Backup: 'warning',
        Admin: 'danger',
        /* eslint-enable key-spacing */
      }

      return presentation => presentationColor[presentation]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    // initialUsers() {
    //   return this.$store.state.users.initialUsers
    // },
    // allUsers() {
    //   return this.$store.state.users.allUsers
    // },
  },
  created() {
    this.loadItems()
    this.fetchTags()
  },
  mounted() {
    // const refreshId = setInterval(() => {
    //   if (this.initialUsers) {
    //     this.users = this.customDomain(this.initialUsers)
    //     clearInterval(refreshId)
    //   }
    // }, 100)

    // const refreshId2 = setInterval(() => {
    //   if (this.allUsers) {
    //     this.users = this.customDomain(this.allUsers)
    //     this.done = true
    //     clearInterval(refreshId2)
    //   }
    // }, 100)
  },
  methods: {
    fetchTags() {
      this.$store.dispatch('users/fetchUsersTag').then(tags => {
        if (tags?.length === 0) this.tags = [{ id: 0, tag_name: 'No Tag' }]
        this.tags = tags.map(tag => ({ id: tag.id, name: tag.tag_name }))
      })
    },
    handleOkTag() {
      const payload = {
        tag_name: this.newTag,
      }
      axios.post('/api/admin/create_tag', payload).then(res => {
        if (res?.data?.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: res.data.message,
              variant: 'success',
            },
          })
          this.fetchTags()
        }
      })
    },
    editTagName() {
      const payload = {
        id: this.tags.find(tag => tag.name === this.selectedTag).id,
        tag_name: this.selectedTagEdit,
      }
      axios.post('/api/admin/update_tag', payload).then(res => {
        if (res?.data?.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: res.data.message,
              variant: 'success',
            },
          })
          this.loadItems()
          this.fetchTags()
          this.selectedTag = ''
          this.selectedTagEdit = ''
          this.$bvModal.hide('users-tag-modal')
        }
      })
    },
    deleteTagName() {
      const payload = {
        id: this.tags.find(tag => tag.name === this.selectedTag).id,
        tag_name: this.selectedTagEdit,
      }
      axios.post('/api/admin/delete_tag', payload).then(res => {
        if (res?.data?.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: res.data.message,
              variant: 'success',
            },
          })
          this.loadItems()
          this.fetchTags()
          this.selectedTag = ''
          this.selectedTagEdit = ''
          this.$bvModal.hide('users-tag-modal')
        }
      })
    },
    async confirmYes(uid) {
      if (this.selectedTag?.tag_name === 'No Tags') return
      const payload = {
        uid,
        tag: this.selectedTag ? this.selectedTag.name : '',
      }
      this.sendingConfirmYes = true

      await axios.post('/api/admin/add_tag_to_user', payload).then(res => {
        this.loadItems()
        this.askAction = true
        this.askConfirm = false
        this.sendingConfirmYes = false

        if (res?.data?.success) {
          this.selectedTag = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: res.data.message,
              variant: 'success',
            },
          })
          this.selectedTag = ''
          this.selectedTagEdit = ''
        }
      }).catch(error => { console.log(error) })
    },
    setSelectedTag(tag) {
      if (tag?.tag_name === 'No Tags') return
      this.selectedTag = tag
      this.askAction = false
      this.askConfirm = true
      this.askReject = false
    },
    answerNo() {
      this.askAction = true
      this.askConfirm = false
      this.askReject = false
    },
    askConfirmAction() {
      this.askAction = false
      this.askConfirm = true
      this.askReject = false
    },
    askRejectAction() {
      this.askAction = false
      this.askConfirm = false
      this.askReject = true
    },

    customDomain(users) {
      const newUser = []
      for (const user of users) { // eslint-disable-line
        user.customDomain = this.getDomain(user)
        newUser.push(user)
      }
      return newUser
    },
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    fetchAllUsers() {
      this.$store.dispatch('users/fetchAllUsers')
        .catch(error => {
          console.log(error)
        })
    },
    // fetchInitialUsers() {
    //   this.$store.dispatch('users/fetchInitialUsers')
    //     .catch(error => { console.log(error) })
    // },
    userStatus(user) {
      let status = ''

      if (user.administrator === 2) status = 'Moderator'
      else if (user.administrator === 3) status = 'Finance'
      else if (user.is_affiliate === 1) status = 'Affiliate'
      else if (user.user_status === 1) status = 'Lead'
      else if (user.user_status === 2) status = 'Registered'
      else if (user.user_status === 3) status = 'Pending'
      else if (user.user_status === 4) status = 'Trial'
      else if (user.user_status === 5) status = 'Subscribed'
      else if (user.user_status === 6) status = 'Canceled'
      else if (user.user_status === 7) status = 'Expired'
      else if (user.user_status === 8) status = 'Backup'
      else if (user.user_status === 9) status = 'Admin'
      else if (user.user_status === 10) status = 'Suspended'
      else if (user.user_status === 0) status = 'Pending payment'
      // console.log(user.user_status_second)
      if (user.user_status_second === 'Sub Account') status = 'Sub Account'
      return status
    },
    getStartedDate(user) {
      if (user.sub_start) return `Subscribed on:<br>${this.formatDate(user.sub_start)}`

      if (user.trial_start) return `Trial started on:<br>${this.formatDate(user.trial_start)}`

      return `Registered on:<br>${this.formatDate(user.created_at)}`
    },
    cancelUser(userID) {
      this.confirmationAlert('Are you sure you want to cancel this user?')
        .then(res => {
          if (res.isConfirmed) {
            this.$store.dispatch('users/cancelUser', userID)
              .then(() => {
                this.notify({
                  text: 'User has been successfully canceled!',
                  variant: 'success',
                })
                this.loadItems()
              })
              .catch(error => {
                console.log(error)
              })
          }
        })
    },
    impersonate(id) {
      this.$store.dispatch('auth/impersonate', id).then(() => {
        window.location = '/'
      })
    },
    sortFnDate(x, y, col, rowX, rowY) { // eslint-disable-line

      const value1 = Date.parse(rowX.created_at)// get x
      const value2 = Date.parse(rowY.created_at)// get y

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },
    sortFnStatus(x, y, col, rowX, rowY) { // eslint-disable-line

      const value1 = this.userStatus(rowX)
      const value2 = this.userStatus(rowY)
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },

    viewClicksAndConversions(name) {
      this.$store.dispatch('users/setClickSearch', name)
      this.changeTab(1)
    },

    // vue good table
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ page: 1, perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },
    onStatusFilter(value) {
      this.updateParams({ page: 1, perPage: this.serverParams.perPage, search: value !== 'Filter By Status' ? value : '' })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    onSearch() {
      this.updateParams({ page: 1, perPage: this.serverParams.perPage, search: this.searchTerm })
      this.loadItems()
    },
    // load items is what brings back the rows from server
    loadItems() {
      this.isLoading = true
      this.$store.dispatch('users/fetchAllUsersServer', this.serverParams)
        .then(response => {
          this.totalRecords = response.total
          this.users = this.$store.state.users.allUsers
          this.isLoading = false
          this.done = true
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.c-select {
  width: 160px !important;
  float: left;
  margin-left: 0 !important;
}

.users-table {
  .user-action-cancel {
    color: gray !important;
    cursor: auto !important;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }
}

.impersonateIcon {
  cursor: pointer;
  color: gold;
}

.impersonateIcon:hover {
  color: bisque;
}

.adminIcon {
  color: green;
}

.adminIcon:hover {
  color: greenyellow;
}

.adminClass {
  color: red !important;
  cursor: default !important;
}

.dark-layout a {
  color: #fb50a1;
}

.dark-layout .badge.badge-light-primary {
  background-color: rgba(194, 63, 141, 0.49);
}

.dark-layout .badge.badge-light-primary {
  color: #ff89d7 !important;
}
</style>
