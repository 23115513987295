<template>
  <b-card>
    <b-tabs
      v-model="tabIndex"
      content-class="pt-1"
      fill
      pills
    >
      <b-tab
        title="Users"
      >
        <all-users :change-tab="changeTab" />
      </b-tab>
      <b-tab
        title="Clicks And Conversion Reports"
      >
        <clicks-conversions />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'

import AllUsers from './AllUsers.vue'
import ClickAndConversions from './AllClickAndConversions.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    'all-users': AllUsers,
    'clicks-conversions': ClickAndConversions,
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  methods: {
    changeTab(index) {
      this.tabIndex = index
    },
    searchTerm(index) {
      this.tabIndex = index
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
