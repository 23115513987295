<template>
  <b-overlay
    :show="!userReports"
    rounded="sm"
  >
    <div class="click-conversion-table">
      <b-form-select
        v-model="selectedMonth"
        :options="['Current Month','One Month Ago','Two Months Ago']"
        class="c-month"
        @change="changeMonth"
      />
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        v-if="userReports"
        :columns="columns"
        :rows="userReports"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Full Name -->
          <span v-if="props.column.field === 'users_fullname'">
            <div
              style="text-align:left;"
            >
              <feather-icon
                icon="UsersIcon"
                size="16"
                class="impersonateIcon"
                @click="impersonate(props.row.user_id)"
              />
              <router-link
                :to="`/user/details/${props.row.user_uid}`"
              >{{ props.row.users_fullname }}</router-link>
              :
              <br>
              <span> {{ props.row.email }}</span>
            </div>
          </span>

          <!-- Column: Domain -->
          <span v-else-if="props.column.field === 'domain'">
            <a
              v-if="props.row.domain !== 'N/A'"
              :href="`//${props.row.domain}`"
              target="_blank"
            >{{ props.row.domain }}</a>
            <span v-else>{{ props.row.domain }}</span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap mt-1">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','25','50']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </b-overlay>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
  },
  data() {
    return {
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Subscription status',
          field: 'user_status',
          sortable: false,
        },
        {
          label: 'Name',
          field: 'users_fullname',
        },
        // {
        //   label: 'Email',
        //   field: 'email',
        // },
        {
          label: 'Domain',
          field: 'domain',
        },
        {
          label: 'Allowed clicks',
          field: 'allowed_clicks',
          type: 'number',
        },
        {
          label: 'Used clicks',
          field: 'used_clicks',
          type: 'number',
        },
        {
          label: 'Overage Clicks',
          field: 'overageClicks',
          type: 'number',
        },
        {
          label: 'Allowed conversions',
          field: 'allowed_conversions',
          type: 'number',
        },
        {
          label: 'Used conversions',
          field: 'used_conversions',
          type: 'number',
        },
        {
          label: 'Overage conversions',
          field: 'overageConversions',
        },
      ],
      rows: [],
      searchTerm: '',
      userReports: null,
      selectedMonth: 'Current Month',
      userPackages: null,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    searchStore() {
      return this.$store.state.users.clicksSearch
    },
  },
  watch: {
    searchStore(newVal) {
      this.searchTerm = newVal
    },
  },
  created() {
    this.fetchAllUsers()
  },
  methods: {
    fetchAllUsers() {
      this.$store.dispatch('users/fetchUserReports')
        .then(res => {
          this.userReports = res.firstMonth
          this.userPackages = res.userPackages
          this.userReports = this.mergeRows(this.userReports)
        })
        .catch(error => { console.log(error) })
    },
    getDomain(row) {
      if (row.network_type === 'domain') {
        if (row.domain && row.sub_admin) return `${row.sub_admin}.${row.domain}`
        if (row.domain) return row.domain
        return 'N/A'
      }

      if (row.network_type === 'subdomain') {
        if (row.subdomain) return `${row.subdomain}.trafficmanager.com`
        return 'N/A'
      }

      return 'N/A'
    },
    changeMonth(val) {
      if (val === 'Current Month') this.userReports = this.$store.state.users.userReports.firstMonth
      else if (val === 'One Month Ago') this.userReports = this.$store.state.users.userReports.secondMonth
      else if (val === 'Two Months Ago') this.userReports = this.$store.state.users.userReports.thirdMonth
      this.userReports = this.mergeRows(this.userReports)
    },
    mergeRows(userReports) {
      const { length } = userReports
      const reports = []
      for (let x = 0; x < length; x += 1) {
        const row = this.userReports[x]
        const userPackage = this.getPackage(row.uid)
        if (userPackage) {
          let overage_clicks = row.clicks - userPackage.clicks
          if (overage_clicks < 0) { overage_clicks = 0 } else {
            const overage_price_clicks = parseFloat(overage_clicks) * parseFloat(userPackage.overage_clicks)
            overage_clicks = `${overage_clicks} (€${this.number_format(overage_price_clicks)})`
          }

          let overage_conversion = row.conversions - (userPackage.conversion || 0)
          if (overage_conversion < 0) { overage_conversion = 0 } else {
            const overage_price_conv = parseFloat(overage_conversion) * parseFloat(userPackage.overage_conv || 0)
            overage_conversion = `${overage_conversion} (€${this.number_format(overage_price_conv)})`
          }

          const userStatus = `${this.getStatus(row.user_status)} - ${userPackage.name}`

          const userDomain = this.getDomain(row)
          const userReport = {
            user_status: userStatus,
            domain: userDomain,
            allowed_clicks: userPackage.clicks,
            used_clicks: row.clicks,
            overageClicks: overage_clicks.toString(),
            allowed_conversions: userPackage.conversion,
            used_conversions: row.conversions,
            users_fullname: row.users_fullname,
            email: row.email,
            user_uid: row.user_uid,
            user_id: row.user_id,
            overageConversions: overage_conversion.toString(),
          }
          reports.push(userReport)
        }
      }
      return reports // eslint-disable-line
    },
    getPackage(uid) {
      return this.userPackages.find(item => Number(item.uid) === Number(uid))
    },
    number_format(num) {
      return num.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    },
    getStatus(s) {
      const status = Number(s)
      let statusStr = ''
      if (status === 0) statusStr = 'Pending payment'
      else if (status === 1) statusStr = 'Lead'
      else if (status === 2) statusStr = 'Registered'
      else if (status === 3) statusStr = 'Pending'
      else if (status === 4) statusStr = 'Trial'
      else if (status === 5) statusStr = 'Subscribed'
      else if (status === 6) statusStr = 'Canceled'
      else if (status === 7) statusStr = 'Expired'
      return statusStr
    },
    impersonate(id) {
      this.$store.dispatch('auth/impersonate', id).then(() => {
        window.location = '/'
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.click-conversion-table {
  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }
}
</style>

<style lang="scss" scoped>
.c-month {
  width: 180px;
  float: left;
  margin-left: 0 !important;
}
</style>
